export const functionalities = [
  {
    name: "order",
    label: "Ordenes",
  },
  {
    name: "management",
    label: "Management",
  },
  {
    name: "managementCompanies",
    label: "Management Compañías",
  },
  {
    name: "hub",
    label: "Bodegas",
  },
  {
    name: "users",
    label: "Usuarios",
  },
  {
    name: "report",
    label: "Reportes",
  },
  {
    name: "metrics",
    label: "Métricas",
  },
  {
    name: "inventory",
    label: "Inventario",
  },
];
