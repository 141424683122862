import * as Yup from "yup";
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useMemo, useState, useEffect } from 'react';
import { Button, CardHeader } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from "../../../components/hook-form/FormProvider";
import RHFTextField from "../../../components/hook-form/RHFTextField";
import { SCHEMA_CREATE_MODULE } from "../../../constants/schemas/schema_create_funtionality";
import CreateSubmodule from "./CreateSubmodulo";
import { dispatch } from "../../../redux/store";
import { createFuntionality, getFunctionalities } from "../../../redux/slices/functionalities";

type Props = {
  onCancel: any,
  currentModulo: any
};


export default function CreateFunctionality({ onCancel, currentModulo }: Props) {
  const [dataSubmodule, setDataSubmodule] = useState<any>()

  const SchemaModule = Yup.object().shape(SCHEMA_CREATE_MODULE)

  const defaultValueSubmodule = useMemo(
    () => ({
      id: currentModulo?.id||"", 
      module: currentModulo?.module || "",
      submodules: currentModulo?.submodules || []
    }),
    [currentModulo]
  )

  const methoModule = useForm<any>({
    resolver: yupResolver(SchemaModule),
    defaultValues: defaultValueSubmodule,
  })

  const {
    handleSubmit,
  } = methoModule;

  const saveInfo = async (data: any) => {
    if (dataSubmodule.length > 0) {
      const info = {
        id:data.id,
        module: data.module.toUpperCase(),
        submodules: dataSubmodule
      }     
      if(info.id.length<5){
        delete info.id
      }
      await dispatch(createFuntionality(info))
      dispatch(getFunctionalities())
      onCancel()
    }
  }

  useEffect(()=>{
    setDataSubmodule(currentModulo.submodules)
  },[currentModulo])

  return (
    <>
      <FormProvider methods={methoModule} onSubmit={handleSubmit(saveInfo)}>
        <Card sx={{ mb: 0.5 }}>
          <CardHeader
            title='Crear Funcionalidad'
            titleTypographyProps={{ variant: 'h3' }}
            sx={{ mb: -2, mt: -3 }}
          />
          <CardContent>
            <RHFTextField inputProps={{ style: { textTransform: "uppercase" } }} name="module" label="Nombre del Módulo"/>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title='Submódulos'
            titleTypographyProps={{ variant: 'h4' }}
            sx={{ mb: -2, mt: -2 }}
          />
          <CardContent>
            <CreateSubmodule setSubmodulo={setDataSubmodule} data={dataSubmodule}/>
          </CardContent>
        </Card>
        <Box sx={{mt: 3}}>
          <Stack direction="row" justifyContent="center" width="100%" gap={1}  >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ minWidth: 100 }}
              size="medium"
            >Crear</Button>
            <Button
              variant="contained"
              color="error"
              onClick={onCancel}
              sx={{ minWidth: 100 }}
              size="medium"
            >Cancelar</Button>
          </Stack>
        </Box>
      </FormProvider>
    </>
  );
}
