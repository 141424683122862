import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { UsersState } from '../../../@types/users_state';
import { useSelector } from "../../../redux/store";
import { closeDialogDeleteUser } from '../../../redux/slices/users';
import Iconify from "../../../components/Iconify";

export function DeleteUserDialog() {

    const { dialogDeleteUser } = useSelector<UsersState>((state) => state.users);

    const handleClose = () => {
        closeDialogDeleteUser();
    };

    const invokeCallback = () => {
        handleClose();
        dialogDeleteUser.actionDelete!();
    }

    return (
        <Dialog {...dialogDeleteUser} onClose={handleClose}>
            <DialogTitle>
                <Stack justifyContent="center" direction="row" alignItems="center" paddingY={3}>
                    <Typography variant="h5" color="primary" align="center">Eliminar registro</Typography>
                    <IconButton
                        sx={{ position: "absolute", right: 0, top: 0 }}
                        aria-label="close"
                        onClick={handleClose}
                        color="primary"
                    >
                        <Iconify icon="mdi:close-circle" color="primary" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Typography>¿Estás seguro de querer eliminar el registro seleccionado?</Typography>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="center" width="100%" gap={1}>
                    <Button variant="contained" color="primary" onClick={invokeCallback}>Aceptar</Button>
                    <Button variant="contained" color="error" onClick={handleClose}>Cancelar</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}