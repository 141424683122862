import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { dispatch } from "../store";
import { FunctionalityUtilsState } from "../../@types/functionality_utils";

const initialState: FunctionalityUtilsState = {
  functionalities: [],
};

export const functionalitiesSlice = createSlice({
  name: "functionality",
  initialState,
  reducers: {
    setFunctionalities: (state, action: PayloadAction<Array<string>>) => {
      state.functionalities = action.payload;
    },
  },
});

export default functionalitiesSlice.reducer;

export const getFunctionalities = (rol: string | undefined) => {
  return async () => {
    try {
      if (rol) {
        const response = await axios.get(`/authorizer/v1/rol-permissions?name=${rol}`);
        const functionalities = response.data;
        const functionalitiesList = functionalities[0].permissions.flatMap((permission: any) => {
          return permission.submodules.flatMap((submodule: any) => {
            return Object.entries(submodule.scopes)
              .filter(([_, value]) => value === true)
              .map(([scope]) => `${permission.module.replace(/\s/g, '')}.${submodule.name.replace(/\s/g, '')}.${scope}`);
          });
        });
        dispatch(functionalitiesSlice.actions.setFunctionalities(functionalitiesList));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
