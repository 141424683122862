import { useEffect, useState } from "react";
import { Card, TableContainer } from "@mui/material";
import { Box, Table, TableBody, TablePagination } from "@mui/material";

import UserTableRow from "../list/UserTableRow";
import FilterUsers from "../filter/FilterUsers";
import { UserManager } from "../../../@types/user";
import { useSelector } from "../../../redux/store";
import { _userList, _userRoles } from "../../../_mock";
import { UsersState } from "../../../@types/users_state";
import useTable, { emptyRows, getComparator } from "../../../hooks/useTable";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "../../../components/table";

const TABLE_HEAD_USERS = [
    { id: "name", label: "Nombres", align: "left", width: "150px" },
    { id: "last_name", label: "Apellidos", align: "left", width: "150px" },
    { id: "email", label: "Correo electrónico", align: "left" },
    { id: "group", label: "Cargo", align: "left" },
    { id: "createdAt", label: "Fecha de creación", align: "left", minWidth: "185px" },
    { id: "status", label: "Estado", align: "left", with: "100px", minWidth: "103px" },
    { id: "", label: "Acciones", align: "right" },
];

export default function TableUsers() {

    const { users, pageInfo, } = useSelector<UsersState>((state) => state.users);
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        selected,
        setSelected,
        onSelectAllRows,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({defaultRowsPerPage: 25});

    const [tableData, setTableData] = useState(users);

    const [action, setAction] = useState<number>();

    const isNotFound = false;

    const handleDeleteRow = (id: string) => {
        const deleteRow = tableData.filter((row: any) => row.id !== id);
        setSelected([]);
        setTableData(deleteRow);
    };

    const handleEditRow = (_: string) => {
        //todo: imp edit function
    };

    const dataFiltered = applySortFilter({
        tableData,
        comparator: getComparator(order, orderBy),
    });

    const denseHeight = dense ? 52 : 72;

    useEffect(() => {
        setTableData(users)
    }, [users]);

    return <Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <FilterUsers limit={rowsPerPage} page={page} action={action} />
        <TableContainer sx={{ minWidth: 800, position: "relative", flex: 1 }}>
            <Table size={dense ? "small" : "medium"} stickyHeader>
                <TableHeadCustom
                    sx={{ textAlign: "left" }}
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_USERS}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                        onSelectAllRows(
                            checked,
                            tableData.map((row: any) => row.id)
                        )
                    }
                />

                <TableBody>
                    {dataFiltered
                        .map((row) => (
                            <UserTableRow
                                key={row.id}
                                row={row}
                                selected={selected.includes(row.id)}
                                onDeleteRow={() => handleDeleteRow(row.id)}
                                onEditRow={() => handleEditRow(row.name)}
                            />
                        ))}

                    <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                </TableBody>
            </Table>
        </TableContainer>

        <Box sx={{ position: "relative" }}>
            <TablePagination
                rowsPerPageOptions={[rowsPerPage, rowsPerPage*2, rowsPerPage*4]}
                component="div"
                count={pageInfo.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_, newPage) => {
                    const _action = page < newPage ? 1 : -1;
                    setAction(_action)
                    onChangePage(_, newPage);
                }}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </Box>
    </Card>
}
// ----------------------------------------------------------------------

function applySortFilter({
    tableData,
    comparator,
}: {
    tableData: UserManager[];
    comparator: (a: any, b: any) => number;
}) {
    const stabilizedThis = tableData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    tableData = stabilizedThis.map((el) => el[0]);

    return tableData;
}
