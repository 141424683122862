import { useTheme } from "@mui/material/styles";
import { TableRow, TableCell, Typography, IconButton } from "@mui/material";
import { deleteUser, openDialogDeleteUser } from "../../../redux/slices/users";
import { UserManager } from "../../../@types/user";
import Label from "../../../components/Label";
import Iconify from "../../../components/Iconify";
import { openUpdateUser } from "../../../redux/slices/users";
import { dispatch } from "../../../redux/store";

type Props = {
  row: UserManager;
  selected: boolean;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function UserTableRow({ row, selected }: Props) {
  const theme = useTheme();


  const { id, name, email, last_name, group, status, createdAt } = row;
  const firstGroupItem = group[0];

  const handleDeleteUser = () => {
    return dispatch(deleteUser(`${id}`));
  };

  const handleEditUser = () => {
    return openUpdateUser(row);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ alignItems: "center" }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>
      <TableCell sx={{ alignItems: "center" }}>
        <Typography variant="subtitle2" noWrap>
          {last_name}
        </Typography>
      </TableCell>

      <TableCell align="left">{email}</TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {firstGroupItem}
      </TableCell>

      <TableCell
        align="left"
        sx={{ textTransform: "capitalize", padding: "5px 10px" }}
      >
        {new Date(createdAt * 1000).toDateString()}
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={(status === "INACTIVE" && "error") || "success"}
          sx={{ textTransform: "capitalize" }}
        >
          {status}
        </Label>
      </TableCell>

      <TableCell align="right" sx={{ padding: "5px 10px" }}>
        <IconButton color="primary" onClick={handleEditUser}>
          <Iconify icon={"eva:edit-fill"} />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => openDialogDeleteUser(handleDeleteUser)}
        >
          <Iconify icon={"eva:trash-2-outline"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
