import { TableRow, TableCell, Typography, IconButton, Chip, Stack } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { Functionality } from "types/functionality";
import { openConfirmDeleteDialog } from "../../../redux/slices/utils";
import { deleteFunctionalities, getFunctionalityById, openDialogFunctionality } from '../../../redux/slices/functionalities';
import { dispatch } from '../../../redux/store';

type Props = {
  row: Functionality;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export function FunctionalityTableRow({ row, /*onEditRow*/ /* onDeleteRow */ }: Props) {

  const { module, submodules } = row;

  const handleDeleteFunctuinalities = () => {
    return dispatch(deleteFunctionalities(`${row.id}`));
  }

  const handleUpdateFuntionality = async ()=>{    
    await dispatch(getFunctionalityById({"id": row.id }))
    openDialogFunctionality()
  }
 

  return (
    <TableRow hover>
      <TableCell sx={{ alignItems: "center", padding: "5px 10px" }}>
        <Typography variant="subtitle2" noWrap>
          {module}
        </Typography>
        <Stack paddingY={1} spacing={1} direction="row">
          {submodules.map((s) => <Chip label={s.name} variant="outlined" color="primary" />)}
        </Stack>
      </TableCell>

      <TableCell align="right" sx={{ padding: "5px 10px" }}>
        <IconButton
          color="primary"
          onClick={() =>handleUpdateFuntionality()}
        >
          <Iconify icon={"eva:edit-fill"} />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => openConfirmDeleteDialog(handleDeleteFunctuinalities)}
        >
          <Iconify icon={"eva:trash-2-outline"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
