import { createSlice } from "@reduxjs/toolkit";

import { dispatch } from "../store";
import axios from "../../utils/axios";
import { DialogType, User } from "../../@types/user";
import { DialogDelete, UsersState } from "../../@types/users_state";
import { USER_PATH } from "../../constants/paths";
import { UserFilterResponse } from "types/user-filter-response";
import { closeLoading, openLoading, setAlert } from "./utils";
import { Errors } from "../../components/Alert";

const path = USER_PATH;

const initialState: UsersState = {
  users: [],
  loading: false,
  dialogNewUser: { open: false },
  dialogDeleteUser: { open: false },
  pageInfo: {
    count: 0,
    totalCount: 0,
    endCursor: "",
    startCursor: "",
  },
  noData: false,
  loadingData: true,
  updateUser: { open: false },
};

const slices = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoadingData(state, action) {
      state.loadingData = action.payload;
    },
    setUsers(state, actions) {
      state.users = actions.payload.users;
      state.pageInfo = actions.payload.info;
    },
    setLoading(state, actions: { payload: boolean }) {
      state.loading = actions.payload;
    },
    dialogNewOrder(state, actions: { payload: DialogType<any> }) {
      state.dialogNewUser = actions.payload;
    },
    setNoData(state, actions: { payload: boolean }) {
      state.noData = actions.payload;
    },
    setUpdateUser(state, actions: { payload: DialogType<any> }) {
      state.updateUser = actions.payload;
    },
    dialogDeleteUser(state, actions: { payload: DialogDelete }) {
      state.dialogDeleteUser = actions.payload;
    },
  },
});

export default slices.reducer;

export function searchUsers(params: Record<string, any>) {
  dispatch(slices.actions.setNoData(false));
  return async () => {
    try {
      const filters = { ...params };
      for (const key in filters) {
        filters[key] = filters[key].value ?? filters[key];
      }
      const response = await axios.get<UserFilterResponse>(`${path}/user`, {
        params: filters,
      });
      const nodata = response.data.users.edges.length === 0;
      dispatch(slices.actions.setNoData(nodata));
      const users = response.data.users.edges.map((o) => {
        return { ...o.node, name: o.node.names, lastName: o.node.surname };
      });
      dispatch(
        slices.actions.setUsers({ users, info: response.data.users.pageInfo })
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slices.actions.setLoadingData(false));
    }
  };
}

export function deleteUser(id: string) {
  return async () => {
    try {
      openLoading();
      await axios.patch(`${path}/user`, { id: id, status: "INACTIVE" });
      const params = {
        limit: 25,
        status: "ACTIVE",
      };
      await dispatch(searchUsers(params));
    } catch (error) {
      setAlert(Errors.E100);
      closeLoading();
    } finally {
      closeLoading();
    }
  };
}

export function cleanUsers() {
  dispatch(slices.actions.setUsers({ users: [], info: initialState.pageInfo }));
}

export function setLoadingData(loading: boolean) {
  dispatch(slices.actions.setLoadingData(loading));
}

export function saveUser(user: User) {
  return async () => {
    const [companyCode, companyName] = user.companyCode.split("|")
    const functionality = user.functionality?.join(",");

    const data = {
      names: user.names.toLocaleUpperCase(),
      last_name: user.last_name.toLocaleUpperCase(),
      surname: user.surname ? user.surname.toLocaleUpperCase() : "",
      email: user.email,
      companyCode,
      companyName,
      functionality,
      group: [user.group, user.hasGroup]
    };

    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        delete data[key];
      }
    });

    return axios.post(`${path}/user`, data);
  };
}

export function openDialogUser() {
  dispatch(slices.actions.dialogNewOrder({ open: true }));
}

export function closeDialogUser() {
  dispatch(slices.actions.dialogNewOrder({ open: false }));
}

export function editUser(user: any) {
  return async () => {
    const [companyCode, companyName] = user.companyCode.split("|")
    const functionality = user.functionality?.join(",");

    const data = {
      ...user,
      id: user.id,
      names: user.names.toLocaleUpperCase(),
      last_name: user.last_name.toLocaleUpperCase(),
      surname: user.surname ? user.surname.toLocaleUpperCase() : "",
      companyCode,
      companyName,
      functionality,
      group: [user.group, user.hasGroup],
    };

    return axios.patch(`${path}/user`, data);
  };
}

export function openDialogDeleteUser(callback: VoidFunction) {
  dispatch(
    slices.actions.dialogDeleteUser({ open: true, actionDelete: callback })
  );
}

export function closeDialogDeleteUser() {
  dispatch(
    slices.actions.dialogDeleteUser({ open: false, actionDelete: undefined })
  );
}

export function openUpdateUser(data?: any) {
  dispatch(slices.actions.setUpdateUser({ open: true, data }));
}

export function closeUpdateUser() {
  dispatch(slices.actions.setUpdateUser({ open: false }));
}
