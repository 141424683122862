import { paramCase } from "change-case";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableContainer,

} from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import useTable, { getComparator, emptyRows } from "../../../hooks/useTable";
import { RoleManager } from "types/user";
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from "../../../components/table";
import { TABLE_HEAD_ROLES } from '../../../constants/roles/roles';
import RoleTableRow from './RoleTableRow';
import { useQuery } from "@tanstack/react-query";
import { dispatch } from "../../../redux/store";
import { searchRoles } from "../../../redux/slices/roles";

export function RoleContainer({ roles }: { roles: any }) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    setSelected,
    onSort,
  } = useTable({defaultOrderBy: "created_at"});

  const [headerTable] = useState(TABLE_HEAD_ROLES);
  const [tableData, setTableData] = useState<any>([]);
  const navigate = useNavigate();
  useQuery({
    queryKey: ["roles"],
    queryFn: () => {
      return dispatch(searchRoles());
    }
  });

  useEffect(() => {
    setTableData(roles);
  }, [tableData]);


  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row: any) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound = false;

  return (
    <TableContainer sx={{ minWidth: 800, position: "relative" }}>
      <Table
        size={dense ? "small" : "medium"}
        stickyHeader>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={headerTable}
          rowCount={tableData.length}
          numSelected={selected.length}
          onSort={onSort}
        />

        <TableBody>
          {dataFiltered
            .map((row: any) => (
              <RoleTableRow
                key={row.id}
                row={row}
                selected={selected.includes(row.id)}
                onDeleteRow={() => handleDeleteRow(row.id)}
                onEditRow={() => handleEditRow(row.name)} />
            ))}

          <TableEmptyRows
            height={denseHeight}
            emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

          <TableNoData isNotFound={isNotFound} />
        </TableBody>
      </Table>
    </TableContainer>
  );

}
function applySortFilter({
  tableData,
  comparator,
}: {
  tableData: RoleManager[];
  comparator: (a: any, b: any) => number;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);
  return tableData;
}
