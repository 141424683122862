import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { FunctionalitiesState } from 'types/functionalities_state';

// types store
import { RolesState } from '../@types/roles_state';
import { UsersState } from '../@types/users_state';
import { UtilsState } from '../@types/utils_state';
import { FunctionalityUtilsState } from '../@types/functionality_utils';

// reducers
import rolesReducer from "./slices/roles";
import usersReducer from "./slices/users";
import utilsReducer from "./slices/utils";
import functionalitiesReducer from "./slices/functionalities";
import functionality from './slices/functionality';

// config persist
export const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

// slices
export type StoreState = {
    users: UsersState;
    roles: RolesState;
    utils: UtilsState;
    functionalities: FunctionalitiesState;
    functionality: FunctionalityUtilsState;
}

// -------------------------------------------------------------------
const rootReducer = combineReducers<StoreState>({
    users: usersReducer,
    roles: rolesReducer,
    utils: utilsReducer,
    functionalities: functionalitiesReducer,
    functionality: functionality,
});

export default rootReducer;
