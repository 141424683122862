import {
  Button,
  MenuItem,
  Stack,
  Typography,
  Dialog,
  Divider,
} from "@mui/material";
import * as Yup from "yup";
import { useSelector } from "../../../redux/store";
import { StoreState } from "../../../redux/rootReducer";
import FormProvider from "../../../components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "types/user";
import {
  closeDialogUser,
  closeUpdateUser,
  editUser,
  saveUser,
  searchUsers,
} from "../../../redux/slices/users";
import { dispatch } from "../../../redux/store";
import RHFTextField from "../../../components/hook-form/RHFTextField";
import RHFSelect from "../../../components/hook-form/RHFSelect";
import RHFRadioGroup from "../../../components/hook-form/RHFRadioGroup";
import {
  closeLoading,
  openLoading,
  setAlert,
} from "../../../redux/slices/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Errors } from "../../../components/Alert";
import { useEffect } from "react";
import { GroupsEnum } from "../../../enums/GroupsEnum";
import axiosInstance from "../../../utils/axios";
import { COMPANY_PATH } from "../../../constants/paths";
import { user } from '@bou-company/front-utils'
import { functionalities } from "../../../constants/functionalities";
import RHFMultiSelect from "../../../components/hook-form/RHFMultiSelect"
import { getCompany } from "../../../utils/decodeTocken"

const pathCompany = COMPANY_PATH;

const schema = Yup.object().shape({
  names: Yup.string()
    .required("Nombre requerido")
    .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El nombre solo puede contener letras")
    .min(2, "El nombre debe terner al menos dos caracteres")
    .max(30, "El nombre puede tener hasta 30 caracteres"),
  last_name: Yup.string()
    .required("Apellido requerido")
    .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El apellido solo puede contener letras")
    .min(2, "El apellido debe terner al menos dos caracteres")
    .max(30, "El apellido puede tener hasta 30 caracteres"),
  surname: Yup.string()
    .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El apellido solo puede contener letras")
    .max(30, "El apellido puede tener hasta 30 caracteres")
    .nullable(),
  email: Yup.string().required("Correo requerido").email("Correo invalido"),
  companyCode: Yup.string().required("Seleccione una compañía"),
  functionality: Yup.array().required("La funcionalidad es requerida"),
  group: Yup.string().required("Grupo requerido"),
  hasGroup: Yup.string().required("Grupo requerido"),
});

const schemaCompany = Yup.object().shape({
  names: Yup.string()
    .required("Nombre requerido")
    .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El nombre solo puede contener letras")
    .min(2, "El nombre debe terner al menos dos caracteres")
    .max(30, "El nombre puede tener hasta 30 caracteres"),
  last_name: Yup.string()
    .required("Apellido requerido")
    .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El apellido solo puede contener letras")
    .min(2, "El apellido debe terner al menos dos caracteres")
    .max(30, "El apellido puede tener hasta 30 caracteres"),
  surname: Yup.string()
    .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El apellido solo puede contener letras")
    .max(30, "El apellido puede tener hasta 30 caracteres")
    .nullable(),
  email: Yup.string().required("Correo requerido").email("Correo invalido"),
  companyCode: Yup.string(),
  functionality: Yup.array(),
  group: Yup.string(),
  hasGroup: Yup.string(),
});

const isAdmin = () => {
  return user?.some((user: string) => user !== "BOUADMIN")
}

export default function CreateUserDialog() {
  const {
    roles: { roles },
    users: { dialogNewUser },
  } = useSelector<StoreState>((state) => state);
  const methods = useForm<User>({
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldUnregister: false,
    defaultValues: user?.some((user: string) => user === "BOUADMIN") ?
      {
        names: "",
        last_name: "",
        email: "",
        functionality: [],
      } :
      {
        names: "",
        last_name: "",
        email: "",
        functionality: ["order", "hub"],
        companyCode: `${getCompany()}`,
        group: `COMPANYROL`,
        hasGroup: `${GroupsEnum.COMPANYADMIN}`
      },
  });

  const getCompanies = (): Promise<[]> => {
    return axiosInstance.get<[]>(`${pathCompany}/all-companies`).then((response) => response.data);
  };

  const { handleSubmit } = methods;

  const handleCloseDialog = () => {
    methods.reset(
      { names: "", last_name: "", email: "", functionality: [] },
      { keepValues: false }
    );
    closeDialogUser();
  };

  const mutation = useMutation({
    mutationFn: (user: User) => {
      return dispatch(saveUser(user));
    },
    onMutate: () => openLoading(),
    onSuccess: () => {
      setAlert(Errors.E005);
      handleCloseDialog();
      closeLoading();
      const params = {
        limit: 25,
        status: "ACTIVE",
      };
      dispatch(searchUsers(params));
    },
    onError: (error: any) => {
      setAlert({
        type: "error",
        message: error.message,
        open: true,
      });

      closeLoading();
    },
  });

  const handleOnSubmit = async (users: User) => {
    const validation = await methods.trigger();
    if (validation) {
      mutation.mutate(users);
    }
  };
  const { data: companies } = useQuery(["companies"], getCompanies);

  return (
    <Dialog
      {...dialogNewUser}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="md"
    >
      <Stack direction="row" spacing={2}>
        <Stack
          direction="column"
          spacing={2}
          sx={{ width: "100%", paddingY: 2 }}
        >
          <Stack
            sx={{ width: "100%", paddingX: 5 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="black">
              CREAR USUARIO
            </Typography>
          </Stack>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
              <Divider sx={{ margin: 1 }} />
              <Typography variant="h6" component="div">
                Datos Personales
              </Typography>
              <Stack direction="row" spacing={2}></Stack>
              <Stack direction="column" spacing={1}>
                <RHFTextField
                  label="Nombre"
                  sx={{ width: "100%" }}
                  name="names"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                />
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={2}>
                    <RHFTextField
                      label="Primer apellido"
                      sx={{ width: "100%" }}
                      name="last_name"
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                    />
                    <RHFTextField
                      label="Segundo apellido"
                      sx={{ width: "100%" }}
                      name="surname"
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
              <Divider sx={{ margin: 1 }} />
              <Typography variant="h6" component="div">
                Datos de Contacto
              </Typography>
              <Stack direction="row" spacing={2}></Stack>
              <Stack direction="column" spacing={1}>
                <RHFTextField
                  label="Correo electrónico"
                  sx={{ width: "100%" }}
                  name="email"
                />
                <Stack direction="column" spacing={2}></Stack>
              </Stack>
            </Stack>
            {isAdmin() ?
              <>
                <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                  <Divider sx={{ margin: 1 }} />
                  <Typography variant="h6" component="div">
                    Empresas
                  </Typography>
                  <Stack direction="column" spacing={2}>
                    <RHFSelect name="companyCode" placeholder="Empresas" label="Empresas">
                      {companies?.map((company: any) => (
                        <MenuItem key={company.code} value={`${company.code}|${company.name}`}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                  <Divider sx={{ margin: 1 }} />
                  <Typography variant="h6" component="div">
                    Funcionalidades
                  </Typography>
                  <Stack direction="column" spacing={2}>
                    <RHFMultiSelect name="functionality" label="Funcionalidades">
                      {functionalities.map((item: any) => (
                        <MenuItem key={item.name} value={`${item.name}`}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </RHFMultiSelect>
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                  <Divider sx={{ margin: 1 }} />
                  <Typography variant="h6" component="div">
                    Roles
                  </Typography>
                  <Stack direction="row" spacing={2}></Stack>
                  <Stack direction="column" spacing={2}>
                    <RHFSelect name="group" placeholder="Roles" label="Roles">
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" sx={{ paddingY: 3 }}>
                  <Divider sx={{ margin: 3 }} />
                  <Typography variant="h6" component="div">
                    Indique el grupo
                  </Typography>
                  <Divider sx={{ margin: 3 }} />
                  <Stack direction="column" flex={1} justifyContent="space-around">
                    <RHFRadioGroup
                      defaultValue="false"
                      name="hasGroup"
                      options={[
                        { label: "Administrador Bou", value: GroupsEnum.BOUADMIN },
                        { label: "Administrador Empresa", value: GroupsEnum.COMPANYADMIN },
                      ]}
                    />
                  </Stack>
                </Stack>
              </> : null
            }

            <Stack
              direction="column"
              spacing={1}
              alignItems={"center"}
              margin={3}
            >
              <Stack direction="row" spacing={2}>
                <Button type="submit" variant="contained" color="primary">
                  Guardar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseDialog}
                >
                  Cancelar
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export function UpdateUserDialog() {
  const {
    roles: { roles },
    users: { updateUser },
  } = useSelector<StoreState>((state) => state);

  const getCompanies = (): Promise<[]> => {
    return axiosInstance.get<[]>(`${pathCompany}/all-companies`).then((response) => response.data);
  };
  const userCompany = (allCompanies: any, userCompanies: any) => {
    if(userCompanies !== undefined) {
      const coincidenceCompany = allCompanies.find((company:any) => company.code === userCompanies[0])
      return `${coincidenceCompany.code}|${coincidenceCompany.name}`;
    }
    return "";
  };

  const { data: companies } = useQuery(["companies"], getCompanies);
  useEffect(() => {
    methods.reset({ ...updateUser.data });
  }, [updateUser.data]);

  const methods = useForm({
    mode: "onChange",
    resolver: user?.some((user: string) => user === "BOUADMIN") ? yupResolver(schema)
      : yupResolver(schemaCompany),
    shouldUnregister: false,
    defaultValues: {...updateUser.data},
  });

  const { handleSubmit } = methods;

  const handleCloseDialog = () => {
    closeUpdateUser();
  };

  const mutation = useMutation({
    mutationFn: (user: any) => {
      return dispatch(editUser(user));
    },
    onMutate: () => openLoading(),
    onSuccess: () => {
      setAlert(Errors.E007);
      handleCloseDialog();
      closeLoading();
      const params = {
        limit: 25,
        status: "ACTIVE",
      };
      dispatch(searchUsers(params));
    },
    onError: (error: any) => {
      setAlert({
        type: "error",
        message: error.message,
        open: true,
      });
      closeLoading();
    },
  });
  const handleOnSubmit = async (users: any) => {
    const userData = user?.some((user: string) => user === "BOUADMIN") ? { ...users }
      : { ...users, companyCode: `${getCompany()}` }
    const validation = await methods.trigger();
    if (validation) {
      mutation.mutate(userData);
    }
  };

  return (
    <Dialog {...updateUser} onClose={handleCloseDialog} fullWidth maxWidth="md">
      <Stack direction="row" spacing={2}>
        <Stack
          direction="column"
          spacing={2}
          sx={{ width: "100%", paddingY: 2 }}
        >
          <Stack
            sx={{ width: "100%", paddingX: 5 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="black">
              ACTUALIZAR USUARIO
            </Typography>
          </Stack>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
              <Divider sx={{ margin: 1 }} />
              <Typography variant="h6" component="div">
                Datos Personales
              </Typography>
              <Stack direction="row" spacing={2}></Stack>
              <Stack direction="column" spacing={1}>
                <RHFTextField
                  label="Nombre"
                  sx={{ width: "100%" }}
                  name="names"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                />
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={2}>
                    <RHFTextField
                      label="Primer apellido"
                      sx={{ width: "100%" }}
                      name="last_name"
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                    />
                    <RHFTextField
                      label="Segundo apellido"
                      sx={{ width: "100%" }}
                      name="surname"
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
              <Divider sx={{ margin: 1 }} />
              <Typography variant="h6" component="div">
                Datos de Contacto
              </Typography>
              <Stack direction="row" spacing={2}></Stack>
              <Stack direction="column" spacing={1}>
                <RHFTextField
                  label="email"
                  sx={{ width: "100%" }}
                  name="email"
                  disabled
                />
              </Stack>
            </Stack>
            {isAdmin() ?
              <>
                <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                  <Divider sx={{ margin: 1 }} />
                  <Typography variant="h6" component="div">
                    Empresas
                  </Typography>
                  <Stack direction="column" spacing={2}>
                    <RHFSelect defaultValue={userCompany(companies, updateUser?.data?.company)} name="companyCode" placeholder="Empresas" label="Empresas">
                      {companies?.map((company: any) => (
                        <MenuItem key={company.code} value={`${company.code}|${company.name}`}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                  <Divider sx={{ margin: 1 }} />
                  <Typography variant="h6" component="div">
                    Funcionalidades
                  </Typography>
                  <Stack direction="column" spacing={2}>
                    <RHFMultiSelect name="functionality" label="Funcionalidades">
                      {functionalities.map((item: any) => (
                        <MenuItem key={item.name} value={`${item.name}`}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </RHFMultiSelect>
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                  <Divider sx={{ margin: 1 }} />
                  <Typography variant="h6" component="div">
                    Roles
                  </Typography>
                  <Stack direction="row" spacing={2}></Stack>
                  <Stack direction="column" spacing={2}>
                    <RHFSelect defaultValue={updateUser?.data?.group[0]} name="role" placeholder="Roles" label="Roles">
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" sx={{ paddingY: 3 }}>
                  <Divider sx={{ margin: 3 }} />
                  <Typography variant="h6" component="div">
                    Indique el grupo
                  </Typography>
                  <Divider sx={{ margin: 3 }} />
                  <Stack direction="column" flex={1} justifyContent="space-around">
                    <RHFRadioGroup
                      defaultValue={updateUser?.data?.group[1]}
                      name="hasGroup"
                      options={[
                        { label: "Administrador Bou", value: GroupsEnum.BOUADMIN },
                        { label: "Administrador Empresa", value: GroupsEnum.COMPANYADMIN },
                      ]}
                    />
                  </Stack>
                </Stack>
              </> : null
            }
            <Stack
              direction="column"
              spacing={1}
              alignItems={"center"}
              margin={3}
            >
              <Stack direction="row" spacing={2}>
                <Button type="submit" variant="contained" color="primary">
                  Guardar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseDialog}
                >
                  Cancelar
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Stack>
    </Dialog>
  );
}
