import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { FunctionalitiesState } from "types/functionalities_state";
import { dispatch } from "../store";
import { AUTHORIZER_PATH } from "../../constants/paths";
import { closeLoading, openLoading, setAlert } from "./utils";
import { DialogType } from "types/user";
import { Errors } from "../../components/Alert"

const path = AUTHORIZER_PATH;

const initialState: FunctionalitiesState = {
    functionalities: { list: [], detail: {} },
    loading: false,
    dialogNewFuntionality: { open: false },
};

const slices = createSlice({
    name: "functionalities",
    initialState: initialState,
    reducers: {
        setFunctionalities(state, actions) {
            state.functionalities['list'] = actions.payload;
        },
        setFuncionalityById(state, actions) {
            state.functionalities['detail'] = actions.payload;
        },
        dialogNewOrder(state, actions: { payload: DialogType<any> }) {
            state.dialogNewFuntionality = actions.payload;
        },

    },
});

export default slices.reducer;


export function getFunctionalities() {
    return async () => {
        try {
            const response = await axios.get(`${path}/functionalities`);
            dispatch(slices.actions.setFunctionalities(response.data));
        } catch (error) {

        }
    }

}
export function deleteFunctionalities(id: string) {
    return async () => {
        try {
            openLoading();
            await axios.patch(`${path}/functionalities`, { id, status: "DELETED" });
            dispatch(getFunctionalities()).then(() => {
                closeLoading();
            });

        } catch (error: any) {
            setAlert(Errors.E006);
            closeLoading();
        }
    }
}

export function getFunctionalityById(params: Record<string, any>) {
    return async () => {
        try {
            const response = await axios.get(`${path}/functionalities`, { params });
            dispatch(slices.actions.setFuncionalityById(response.data));
        } catch (error) {

        }
    }
}

export function createFuntionality(functionality: any) {
    return async () => {
        try {
            openLoading();
            if(functionality.id === undefined){
                await axios.post(`${path}/functionalities`, functionality);
            }  
            else{
                await axios.patch(`${path}/functionalities`, functionality);
            }
        } catch (error) {
            console.log('Error createRoles: ', error)
        } finally {
            closeLoading();
        }
    }
}

export function openDialogFunctionality() {
    dispatch(slices.actions.dialogNewOrder({ open: true }))
}

export function closeDialogFunctionality() {
    dispatch(slices.actions.dialogNewOrder({ open: false }))
}