import {
    TableCell,
    TableBody,
    Box,
    Dialog,
    Typography,
    Card,
    CardHeader,
    CardContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    IconButton,
    Stack,
    Button,
} from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";

import { SubModules } from "types/user";
import Iconify from "../../../components/Iconify";
import { Errors } from "../../../components/Alert";
import { Functionality } from "types/functionality";
import { createRol, Module } from "types/create_rol";
import { StoreState } from "../../../redux/rootReducer";
import { dispatch, useSelector } from "../../../redux/store";
import { RoleDialog as RoleDialoType } from "types/roles_state";
import { getFunctionalities } from "../../../redux/slices/functionalities";
import { closeLoading, openLoading, setAlert } from "../../../redux/slices/utils";
import { FormProvider, RHFSwitch, RHFTextField } from "../../../components/hook-form";
import { closeModal, createRoles, editRole, searchRoles } from "../../../redux/slices/roles";


const schema = Yup.object().shape({
    name: Yup.string()
        .required("El nombre del rol es requerido")
        .min(4, "Al menos debe contener 4 caracteres")
        .matches(/^[a-zA-Z ñÑáéíóúÁÉÍÓÚ´]+$/, { message: "Nombre no válido" }),
});

export function RoleDialog() {
    const { roles: { dialog }, functionalities: { functionalities } } = useSelector<StoreState>((state) => state);

    useQuery({
        queryKey: ["functionalities"],
        refetchOnWindowFocus: false,
        queryFn: () => {
            return functionalities.list.length == 0 ?
                dispatch(getFunctionalities()) :
                new Promise((resolve) => resolve([]));
        },
    });

    const handleClose = () => {
        closeModal();
    };

    return <Dialog {...dialog} onClose={handleClose} maxWidth="xl">
        <Stack height="90vh">
            <FormRoleDialog dialog={dialog} functionalities={functionalities.list} close={handleClose} />
        </Stack>
    </Dialog>
}

function Row(props: { row: any, index: number }) {
    const { row, index } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: open ? "aliceblue" : "transparent" }}>
                <TableCell align="left" width={250}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography align="left" variant="subtitle2">{row.module}</Typography>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <Iconify icon="mdi:chevron-down" /> : <Iconify icon="mdi:chevron-up" />}
                        </IconButton>
                    </Stack>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table aria-label="purchases" sx={{ marginLeft: "-10px" }}>
                                <TableBody>
                                    {row.submodules.map((subModulo: any, subIdx: number) => (
                                        <TableRow key={subModulo.name}>
                                            <TableCell padding="none">
                                                <Stack width="151px">
                                                    <Typography align="left" variant="body2" sx={{ fontWeight: 'bold', fontSize: "0.7rem" }}>
                                                        {subModulo.name}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ padding: "3px 0" }} width="10px" align='left'>
                                                <RHFSwitch label="" name={`permissions.${index}.submodules.${subIdx}.scopes.list`} />
                                            </TableCell>
                                            <TableCell sx={{ padding: 0, paddingLeft: "0px" }} align="center">
                                                <RHFSwitch label="" name={`permissions.${index}.submodules.${subIdx}.scopes.create`} />
                                            </TableCell>
                                            <TableCell sx={{ padding: 0, paddingRight: "10px", paddingY: "3px" }} align="center">
                                                <RHFSwitch label="" name={`permissions.${index}.submodules.${subIdx}.scopes.edit`} />
                                            </TableCell>
                                            <TableCell sx={{ padding: 0, paddingRight: "10px", paddingY: "3px" }} align="center">
                                                <RHFSwitch label="" name={`permissions.${index}.submodules.${subIdx}.scopes.delete`} />
                                            </TableCell>
                                            <TableCell sx={{ padding: 0, textAlign: "end", paddingY: "3px", "&:last-of-type": { paddingRight: 0 } }}>
                                                <RHFSwitch label="" name={`permissions.${index}.submodules.${subIdx}.scopes.download`} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


function FormRoleDialog(props: { dialog: RoleDialoType, functionalities: Functionality[], close: () => void }) {

    const { dialog, functionalities, close } = props;
    const [functionality, setFunctionality] = useState<Module[] | void>();

    useEffect(() => {
        const flag = createObjectFunc(functionalities).permissions.sort((a: any, b: any) => a.module.localeCompare(b.module))
        flag.forEach((item) => {
            item.submodules.sort((a, b) => a.name.localeCompare(b.name));
        });
        setFunctionality(flag)
    }, [])

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: dialog.data ?? createObjectFunc(functionalities),
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = methods;

    const mutate = useMutation({
        mutationFn: (role: createRol) => {
            return role.id ? dispatch(editRole(role)) : dispatch(createRoles(role));
        },
        onMutate: () => openLoading(),
        onSuccess: () => {
            dispatch(searchRoles()).then(() => {
                closeLoading();
                closeModal();
                methods.getValues().id ? setAlert(Errors.E004) : setAlert(Errors.E003);
            });
        },
        onError: (_) => {
            closeLoading();
            methods.getValues().id ? setAlert(Errors.E002) :
                setAlert(Errors.E001);
        }
    });

    function createObjectFunc(func: any) {
        let objectFinal: createRol = {
            name: '',
            permissions: []
        };

        func.map((f: any) => {
            let module1: Module = {
                module: f['module'],
                submodules: []
            };
            f['submodules'].map((sub: any) => {
                let submodules: SubModules = {
                    name: sub['name'],
                    scopes: {
                        "edit": false,
                        "create": false,
                        "delete": false,
                        "list": false,
                        "download": false
                    }
                }
                module1.submodules.push(submodules)
            })
            objectFinal.permissions.push(module1);
        });
        objectFinal.permissions.forEach((item) => {
            item.submodules.sort((a, b) => a.name.localeCompare(b.name));
        });
        return objectFinal
    }

    const handleOnSubmit = async () => {
        const validation = await methods.trigger();
        if (validation) {
            mutate.mutate(methods.getValues());
        }
    }

    useEffect(() => {
        if (dialog.data) {
            const updatedPermissions = dialog.data.permissions.map(permission => {
                const sortedSubmodules = permission.submodules.slice().sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    return nameA.localeCompare(nameB);
                });
                return { ...permission, submodules: sortedSubmodules };
            });

            const filter = createObjectFunc(functionalities).permissions

            methods.reset({ ...dialog.data, permissions: [...updatedPermissions, ...filter] });
        }
    }, [dialog.data]);




    return <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)} >
        <Stack height="100%">
            <Card>
                <CardHeader title='Roles' />
                <CardContent>
                    <RHFTextField name="name" label="Inserte el nombre del Rol" />
                </CardContent>
            </Card>
            <Card sx={{ marginBottom: 2, flex: 1, display: "flex", flexDirection: "column" }} >
                <Stack paddingX={2} paddingBottom={1} paddingLeft="24px">
                    <Typography variant="h5">Funcionalidades</Typography>
                </Stack>
                <TableContainer component={Paper} sx={{ position: "relative", flex: 1 }}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" size='medium' sx={{ width: '218px' }}>MÓDULO</TableCell>
                                <TableCell align="right">VER</TableCell>
                                <TableCell align="right">CREAR</TableCell>
                                <TableCell align="right">MODIFICAR</TableCell>
                                <TableCell align="right">ELIMINAR</TableCell>
                                <TableCell align="right">DESCARGAR</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {functionality?.map((row, index) => (
                                <Row key={row.module} index={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <Stack direction="row" spacing={2} justifyContent="center" py={2}>
                <Button disabled={false} type="submit" variant="contained" color="primary">
                    Crear
                </Button>
                <Button variant="contained" onClick={() => close()} color="error">
                    Cancelar
                </Button>
            </Stack>
        </Stack>
    </FormProvider >
}
