import * as Yup from "yup";
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';

import { SubModule } from "types/functionality";
import FunctionalityTable from "./FunctionalityTable";
import { SubModules } from '../../../@types/functionalities';
import FormProvider from "../../../components/hook-form/FormProvider";
import RHFTextField from "../../../components/hook-form/RHFTextField";
import { SCHEMA_CREATE_FUNTIONALITY } from "../../../constants/schemas/schema_create_funtionality";

type Props = {
  setSubmodulo: (arg: any) => void,
  data: any;
};

const createRow = (name: string, description: string) => {
  return { name: name.toUpperCase(), description: description.toUpperCase() };
};

export default function CreateSubmodule({ setSubmodulo , data: dataParent }: Props) {
  const [buttonEdit, setButtonEdit] = useState(false);
  const [data, setData] = useState<SubModules[]>([])
  const [currentSubmodulo, setCurrentSubmodulo] = useState<SubModules | null>()
  const [currentSubmoduloUpdate, setUCurrentSubmoduloUpdate] = useState<SubModules | null>()

  const SchemaSubmodule = Yup.object().shape(SCHEMA_CREATE_FUNTIONALITY)

  const defaultValueSubmodule = useMemo(
    () => ({
      name: currentSubmodulo?.name || "",
      description: currentSubmodulo?.description || "",
    }),
    [currentSubmodulo]
  )

  const methoSubmodule = useForm<SubModules>({
    resolver: yupResolver(SchemaSubmodule),
    defaultValues: defaultValueSubmodule,
  })

  const {
    reset,
    handleSubmit,
    //formState,
  } = methoSubmodule;

  useEffect(() => {
    setSubmodulo(data)
  }, [data]);

  useEffect(() => {
    if(dataParent!==undefined)
      dataParent.map((row:SubModule)=> handleAddRow(row))
  }, [dataParent]);
  
  


  const handleAddRow = async (submodulo: SubModule) => {
    if (!buttonEdit) {
      let itemFind: any = {};
      itemFind = data.find(item => item.name === submodulo.name);
      if (buttonEdit === false && itemFind === undefined) {
        setData((prevRows) => [...prevRows, createRow(submodulo.name.toUpperCase(), submodulo.description+'')]);
      }
      reset(defaultValueSubmodule)
    } else {
      data.map((item) => {
        if (item.name === currentSubmoduloUpdate?.name) {
          item.name = submodulo?.name?.toUpperCase() || '';
          item.description = submodulo?.description?.toUpperCase() || '';
          setButtonEdit(false)
        }
      })
      await setData(data)
    }
    reset({ name: "", description: "" })
  };

  const handleDeleteRow = (name: string) => {
    let dataFiltered = data.filter(item => item.name !== name);
    setData(dataFiltered);
  }

  const handleUpdateRow = (name: string) => {
    setButtonEdit(true)
    const dataFiltered = data.find(item => item.name === name);
    setCurrentSubmodulo(dataFiltered)
    setUCurrentSubmoduloUpdate(dataFiltered)
    reset(dataFiltered)
  }

  return (
    <>
      <FormProvider methods={methoSubmodule} >
        <Box sx={{ flexGrow: 1 }}>
          <Stack alignItems='start' flexDirection="row">
            <Stack flex={3}>
              <RHFTextField inputProps={{ style: { textTransform: "uppercase" } }} name="name" label="Nombre del submódulo" />
            </Stack>
            <Stack flex={8} marginLeft={2}>
              <RHFTextField inputProps={{ style: { textTransform: "uppercase" } }} name="description" label="Descripción del Submódulo" />
            </Stack>
            <Stack marginLeft={2} flex={1}>
              {buttonEdit === false && <IconButton color="success" onClick={handleSubmit(handleAddRow)} size='large'>
                <AddCircleSharpIcon />
              </IconButton>}
              {buttonEdit === true && <IconButton color="success" onClick={handleSubmit(handleAddRow)} size='large'>
                <CheckCircleIcon />
              </IconButton>}
            </Stack>
          </Stack>
        </Box>
        <Box>
          
            <FunctionalityTable
              rows={data}
              onDeleteRow={handleDeleteRow}
              onEditRow={handleUpdateRow}
            />
          
        </Box>
      </FormProvider>
    </>
  );
}