import { TableRow, TableCell, Typography, IconButton } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { SubModule } from '../../../@types/functionality';

type Props = {
  row: SubModule;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function FunctionalityTableRow({
  row,
  onDeleteRow,
  onEditRow,
}: Props) {


  const { name, description } = row;
  return (
    <TableRow hover>
      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {name}
      </TableCell>
      <TableCell sx={{ alignItems: "center", width:'100%'}} >
        <Typography variant="subtitle2">
          {description}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={{minWidth: 120}}>
        <IconButton
          color="primary"
          onClick={onEditRow}
        >
          <Iconify icon={"eva:edit-fill"} />
        </IconButton>
        <IconButton
          color="primary"
          onClick={onDeleteRow}
        >
          <Iconify icon={"eva:trash-2-outline"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
