// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Select, FormControl, InputLabel } from "@mui/material";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  children: any;
  label: string;
};

export default function RHFMultiSelect({ name, children, label }: IProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            multiple 
            value={field.value || []} 
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
            error={!!error}
            label={label}
          >
            {children}
          </Select>
          {error && <div>{error.message}</div>}
        </FormControl>
      )}
    />
  );
}
