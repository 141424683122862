import {
    Dialog,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { StoreState } from "../../../redux/rootReducer";
import { dispatch, useSelector } from "../../../redux/store";
import { getFunctionalities, closeDialogFunctionality } from '../../../redux/slices/functionalities';
import DialogContent from '@mui/material/DialogContent';
import CreateFunctionality from '../form/CreateFunctionality';

export function FunctionalityDialog() {
    const { functionalities: { dialogNewFuntionality }, functionalities: { functionalities } } = useSelector<StoreState>((state) => state);

    useQuery({
        queryKey: ["functionalities"],
        refetchOnWindowFocus: false,
        queryFn: () => {
            return functionalities.list.length == 0 ?
                dispatch(getFunctionalities()) :
                new Promise((resolve) => resolve([]));
        },
    });

    const handleClose = () => {
        closeDialogFunctionality()
    };
    return <>
        <Dialog {...dialogNewFuntionality} onClose={handleClose} fullWidth maxWidth='md'>
            <DialogContent>
                <CreateFunctionality currentModulo={functionalities.detail} onCancel={handleClose}></CreateFunctionality>
            </DialogContent>
        </Dialog>
    </>
}