export function decodeToken() {
    const token = localStorage.getItem('idToken');
    if (token) {
        var base64Url = token?.split('.')[1];
        var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64 ? base64 : "").split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const json = JSON.parse(jsonPayload);
        return json;
    }
    return null;
}

export function getCompany() {
    const token = decodeToken();
    if (token) {
        return `${token["custom:company"]}|${token["custom:companyName"]}`;
    }
    return null;
}